
.jojo {
  width: 200px;
}

.upgrade-div{
  margin-left: 13px;
  margin-right: 13px;
  background-color: var(--purplepink);
  width: 90%;
  height: 225px;
}

.upgrade-duke-div{
  height: 70%;
}

.will-input-container {
  position: relative;
  padding-top: 30px;

}

.chat-input-field:focus {
  outline: none;
  border-color: #7f8c8d;
}

.will-main-container {
  height: 65vh;
}

.will-content-container {
  height: 100%;
}

.will-output-container {
  height: 100%;
  overflow: auto;
}

.will-output-card-will {
  min-height: 50px;
  width: 66%;
}

.will-output-card-user {
  background-color: var(--purplepink);
  width: 33%;
}

.will-input {
  min-height: 40px;
  max-height: 400px;
  overflow-y: auto;
  font-size: 16px;
  line-height: 1.5;
  resize: none;

  width: 100%;
  border: solid var(--midgrey);
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
  outline: none; /* Disable border color change on focus */

  position: absolute;
  bottom: 0;
  overflow: hidden;

}

.will-loading {
  position: absolute;
  top: 0;
  right: 25px;
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.will-card-body-will {
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}


.will-input {
  height: 35px;
  width: 100%;
  border: solid var(--midgrey);
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
  outline: none; /* Disable border color change on focus */
}

.will-input-container {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.will-suggestion-div {
  background-color: white;
}

.will-output-text-area {
  resize: none;
  border: none;
  outline: none;
  cursor: none;
}

.will-sticky-input-div {
}

.bee-item-debug-text-div {
  width: 200px;
}

.bee-image-container {
  min-height: 270px;
  align-content: center;
}

.create-account-error-div {
  height: 40px;
}

.demo-table {
  width: 1100px;
  overflow: auto;
  padding-bottom: 15px;
  ;
}

.link-icon {
  width: 20px;
  height: 20px;
}

.link-div {
  width: 65px;
}


.demo-data-link {
  width: 20px;
  height: 20px;
}

.tableau-container {
  position: relative;
}

.tableau_hide_div {
  background-color: white;
  width: 100%;
  height: 27px;
  position: absolute;
}

.data-selection {
  padding-bottom: 30px;
  ;
}


.blured {
  filter: blur(3px)
}

/*  GrowTodo */
/*  button should stay on top after scrolling down */
/* the button should be centered on the page */
.sticky-buy-button-div {
  height: 75px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
}

/*  BeeMain */


/* make it scrollabel */
.hashtag-selection {
  height: 500px;
  overflow-y: scroll;
}

.state-selection {
  height: 450px;
  overflow-y: scroll;
}

/*  LOADING FUll */


.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* testing ----------- */

.red-button {
  background-color: red;
}

.white-button {
  background-color: white;
}

/* GROW ITEM ----------- */

.grow-item-row {
  height: 80px;
}


.grow-item {
  padding-bottom: 13px;
}

.grow-item .top-container {
  display: flex;
  padding-bottom: 5px;
}

.grow-item .avatar-container {
  padding-right: 16px;
}

.grow-item .action-container {
  width: 65%;
  position: relative;
}

.grow-item .button-div {
  width: 50px;
  right: 0px;
  top: 0px;
  position: absolute;
}

.grow-item .name-container {
  padding-top: 30px;
}

.grow-item .button-container {
  padding-bottom: 5px;
  height: 80px;
}

.grow-item .later-container {
  padding-bottom: 5px;
}

.grow-item .grow-button {
  width: 100%;
}

.grow-item .bio-container {
  padding-bottom: 5px;
  max-width: 80vW;
}

.grow-item .details {
  padding-left: 16px;
  text-align: center;
}

/* DU-Styles */

.du-font {
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
  font-weight: 600;
}

.du-font-bold {
  font-weight: bold;
}

.du-font-1 {
  font-size: 11px;
}

.du-font-2 {
  font-size: 13px;
}

.du-font-3 {
  font-size: 15px;
}

.du-font-4 {
  font-size: 19px;
}

.du-font-5 {
  font-size: 25px;
}

.du-font-6 {
  font-size: 28px;
}

.du-font-7 {
  font-size: 32px;
}

.du-font-8 {
  font-size: 40px;
}

.du-font-9 {
  font-size: 45px;
}

.du-font-10 {
  font-size: 60px;
}

.du-font-midgrey {
  color: var(--midgrey);
}

.du-font-lightgrey1 {
  color: var(--midgrey);
}

.du-font-dark {
  color: var(--anthracite);
}

.du-font-pink {
  color: var(--purplepink);
}

.du-font-mango {
  color: var(--mango);
}

.du-font-white {
  color: white;
}

.du-button {
  background-color: var(--mango);
  color: var(--anthracite);
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-simple {

  cursor: pointer;
  color: var(--purplepink);
  border: 1px solid var(--purplepink);
  /* background: transparent; */
  background: white;
  border-radius: 9999px;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 700;
}

.du-button-simple-disabled {

  color: var(--midgrey);
  border: 1px solid var(--midgrey);
  background: transparent;
  border-radius: 9999px;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 700;
}

.du-border-pink {
  border-radius: 1px;
  border-color: var(--purplepink);
  border-width: 1px;
  border-style: solid;
}

.du-button-pink {
  background-color: var(--purplepink);
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-pink:hover {
  background-color: var(--purplepink);
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-outline {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-grey {
  background-color: var(--midgrey);
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-black {
  background-color: var(--anthracite);
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.du-button-mango {
  background-color: var(--mango);
  color: white;
}

.du-button-white {
  background-color: var(--white);
  color: var(--midgrey);
  border: 1px solid #dce4ec;
}

/* .du-button:hover {
    background-color: #fabe8c;
  } */

/* .du-button-pink:hover {
    background-color: var(--darkgrey);
  } */

.du-card {
  border-radius: 20px;
  box-shadow: 8px 6px 45px -4px rgba(0, 0, 0, 0.09);
  background-color: var(--white);
  color: var(--midgrey);
  margin-bottom: 30px;
  padding: 20px 18px;
  overflow-x: hidden;
}

.du-card .card-name {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--midgrey);
  margin-left: auto;
  margin-right: auto;
}

.du-card .card-text {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--anthracite);
  padding-right: 5px;
}

.du-card .card-date {
  width: 220px;
  height: 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--midgrey);
}

.du-card .card-img {
  width: 80px;
  border-radius: 10px;
}