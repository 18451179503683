:root {
    --anthracite: #3c3c3c;
      --purplepink: #d21464;
      --midgrey:#828282;
      --mango:#fabe46;
      --white:#ffffff;
      --lightgrey:#f0f0f0;
      --okgreen:#a2bd6b;
      --lightgrey1:#bbcbda;
  }
  